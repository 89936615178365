import logo from "./logo.svg";
import MainLayout from "./compoments/Layout/Main";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Search from "./compoments/Layout/Search";
import Product from "./compoments/Layout/Product";
import "./App.css";
import '/node_modules/bootstrap/dist/css/bootstrap.min.css'




const App = () => {
  const router = createBrowserRouter([

    {
      path: "/",
      element: <MainLayout DOMS={Search} />,
      children: []
    }
    , {
      path: "/product/:productID",
      element: <MainLayout DOMS={Product} />,
      children: []
    }
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
