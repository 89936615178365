import React, { useEffect, useState } from 'react'
import { Col, Row, Image, Button } from 'antd';
import { useParams } from "react-router-dom";
import OChart from './OChart';
import Request from '../../Requests';
import sortBy from 'sort-by';




const ProductPage = (props) => {
    let { product, productID } = props;
    const [productLowestPrice, setProductLowestPrice] = useState(0);

    useEffect(() => {
        if (product != null) {
            product.sort(sortBy('price'));
            setProductLowestPrice(product[0].price);
        }
    }, [product])

    return (
        <>
            <div className=''>
                <Row gutter={10}>
                    <Col offset={2} md={8}>
                        <Image src={`https://www.costco.com.tw${product[0].images[0]}`} />
                    </Col>
                    <Col offset={6} md={8} className="container" style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-around' }}>
                        <div>
                            <h1>{product[0].name}</h1>
                            <h3>售價 {parseInt(product[0].price)}</h3>
                            <h4>歷史最低售價 {parseInt(productLowestPrice)}</h4>
                            <Button type="primary" href={"https://www.costco.com.tw/p/" + productID}>前往購買</Button>
                        </div>
                        <div>
                            <OChart {...props} />
                        </div>

                    </Col>
                </Row>
            </div>
        </>
    )
}

const App = (props) => {
    let { productID } = useParams(); //get productID from RouteParam
    const [isLoading, setLoading] = useState(true);
    const [product, setProduct] = useState(null);
    useEffect(() => {
        Request.get(`/product/${productID}`)
            .then((res) => {
                setProduct(res.data.reverse());
                setLoading(false);
            })
            .catch((err) => {
                console.warn(err);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <div>loading...</div>
    }
    return (
        <ProductPage product={product} productID={productID} />
    )

}

export default App;