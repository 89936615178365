import React, { useState, useEffect } from 'react';
import { AutoComplete, Input, Card } from 'antd';
import Request from '../../Requests';
import { Link } from "react-router-dom";
import ProductWaterFall from './ProductWaterFall';
import Loading from './Loading';
const renderItem = (title, id) => ({
  value: title,
  label: (
    <Link to={"/product/" + id}>
      <span>
        {title}
      </span>
    </Link>


  ),
});

const App = () => {
  const [searchResult, setSearchResult] = useState([
  ]);
  const [isLoading, setLoading] = useState(true);
  const [allProduct, setAllProduct] = useState(null);

  const handleSearch = (value) => {
    if (value) {
      Request.get(`/search?name=${value}`)
        .then((res) => {
          setSearchResult(() => {
            return res.data.map((item) => {
              return renderItem(item._id.name, item._id.id);
            })
          });

        })
        .catch((err) => {
          console.warn(err);
        });
    }
  }

  useEffect(() => {
    Request.get('/getAllProducts')
      .then((res) => {
        setAllProduct(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.warn(err);
      })
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    )

  } else {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "60px 0" }}>
          <h1>好事多商品搜尋</h1>
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            style={{
              height: 50,
              width: 250,
              // margin: "60px 0"
            }}
            options={searchResult}
            onChange={handleSearch}
          >
            <Input size="large" placeholder='輸入點甚麼' />
          </AutoComplete>
        </div>
        <ProductWaterFall products={allProduct} />
      </>



    )
  }
};
export default App;