import React from 'react'
import { Card, Row, Col } from 'antd'
import { Link } from "react-router-dom";
const { Meta } = Card;

const App2 = (props) => {
    // console.log(props)
    return (
        <Link to={`product/` + props._id.id}>
            <Card
                hoverable
                cover={<img alt="example" src={`https://www.costco.com.tw/` + props._id.image[0]} />}
            // `/product/${props._id.id}`
            >
                <Meta title={props._id.name} description={`售價 ` + parseInt(props._id.price)} />
            </Card>
        </Link>
    )
}

export default function App(props) {
    console.log(props.products);
    return (
        <Row gutter={[20, 30]} style={{ padding: 20 }}>
            {props.products.map((item, index) => {
                if (item._id.name != null) {
                    return (
                        <Col key={index} md={4}>
                            <App2 {...item} />
                        </Col>
                    )
                }
            })}
        </Row>
    )
}