import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




export default function App(props) {
  const { product } = props;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${product[0].name} 售價分析圖`,
      },

    },
    scales: {
      x: {
        ticks: {
          // CLEAR FUCKING X AXIS
          callback: function () {
            return;
          }
        }
      }
    }

  }

  const getLabels = () => {
    let labels = [];
    let p2 = product.reverse()
    p2.forEach((item, index) => {
      console.log()
      labels.push(item.updated_date);
    });
    return labels;
  }
  let chartData = {
    labels: getLabels(),
    datasets: [
    ],
  };
  let price = [];
  product.forEach((item, index) => {
    price.push(item.price);
    console.log(chartData.labels)
  });
  chartData.datasets.push({
    label: product[0].name,
    data: price,
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
  });

  return <Line options={options} data={chartData} />;
}
