import React from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Link } from "react-router-dom";
const { Header, Content, Footer } = Layout;


const App = (props) => {
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const { DOMS } = props;
  return (
    <Layout className="layout">
      <Header>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={new Array(1).fill(null).map((_, index) => {
            const key = index + 1;
            return {
              key,
              label: <Link to="/">首頁</Link>
            };
          })}
        />
      </Header>
      <Content
        style={{
          padding: "0 50px"
        }}
      >
        {/* <Breadcrumb
          style={{
            margin: "16px 0"
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
        <div
          className="site-layout-content"
          style={{
            background: colorBgContainer
          }}
        >
          <DOMS {...props} />
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center"
        }}
      >
        {/* Created by  */}
      </Footer>
    </Layout >
  );
};
export default App;
